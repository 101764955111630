import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Services from "../../../../services/Services";
import SkeletonLuka from "../../../../components/Loader/skeletonLuka";
import { emailRegex } from "../../../../utils/globals";
import Swal from "sweetalert2/dist/sweetalert2.js";

const FormPay = (props) => {
    const [tokenLuka, setTokenLuka] = useState({});
    const [preloader, setPreloader] = useState(false);
    const {
        global,
        resumeTickets,
        reservationSeats,
        initPay,
        showID,
        detalleRegistro,
        handleModalTerms,
        setTokenLukaUpdate,
        handleCheck,
        t,
        formValid,
        formData,
        setPaymentFailure,
        scrollToFormPay,
        selectedFunction,
        errorTerms,
        updatePayu,
        updateAmountPayu,
        updateValidCounter,
        updateEmailRegisterDetail,
        checkTerms,
        handleErrorMessage,
        handleErrorLuka,
        history,
        validateFormPay,
        totalMount,
        closeSkeleton,
        email,
        deleteReservation,
        showSkeleton,
    } = props;
    useEffect(() => {
        initPayLuka();
    }, []);
    useEffect(() => {
        if (tokenLuka.token && tokenLuka.token.trim() !== "") {
            displayLuka();
        }
    }, [tokenLuka, global.userSession]);
    // useEffect(() => {
    //     //console.log(formData);
    //     if (
    //         formData.firstName.trim() !== "" &&
    //         formData.lastName.trim() !== "" &&
    //         tokenLuka.token &&
    //         tokenLuka.token.trim() !== ""
    //     ) {
    //         //displayLuka();
    //     }
    // }, [formData.firstName, formData.lastName]);

    const displayLuka = async () => {
        const btnColor = getComputedStyle(document.body)
            .getPropertyValue("--c1")
            .replace("#", "");
        const { deleteReservation } = props;
        // console.log(global.lukaMethods, global.paymentMethods);
        let methods = global.userSession.Id
            ? selectedFunction.PaymentMethods &&
              selectedFunction.PaymentMethods.trim() !== ""
                ? selectedFunction.PaymentMethods
                : global.lukaMethods
            : selectedFunction.PaymentMethodsGuest &&
            selectedFunction.PaymentMethodsGuest.trim() !== ""
              ? selectedFunction.PaymentMethodsGuest
              : global.lukaMethods;

        // global.lukaMethods.trim() !== ""
        //     ? global.lukaMethods
        //     : global.paymentMethods;
        // console.log("methodsss: ", methods);
        // // console.log("traza: ",detalleRegistro ? detalleRegistro.Id.toString():"NOIDREGISTRO" );
        //   console.log("displayLuka",selectedFunction.CodigoPagoCuotas)
        //   console.log("deleteReservation----->",deleteReservation)
        try {
            if (tokenLuka.token !== "") {
                window.luka.init(
                    tokenLuka.token,
                    "luka-container",
                    {
                        monto: parseFloat(
                            totalMount.toFixed(
                                global.MostrarDecimales ? "2" : "0"
                            )
                        ),
                        numeroDecimales: global.MostrarDecimales ? 2 : 0,
                        separadorMiles: global.thousandsSeparator,
                        separadorDecimal: global.decimalSeparator
                    },
                    email === "" || !emailRegex.test(email) ? null : email,
                    detalleRegistro
                        ? detalleRegistro.Id.toString()
                        : new Date().getTime(),
                    {
                        moneda:
                            selectedFunction.Iso === "VES"
                                ? global.MonedaPorDefecto
                                : selectedFunction.Iso,
                        seleccion: 2,
                        terminos: {
                            tipo: global.CheckTeminosCondiciones ? "1" : "2",
                            comportamiento:
                                !global.comportamientoTerms ||
                                global.comportamientoTerms === 1
                                    ? handleModalTerms
                                    : global.TextoCondicionesExtras,
                        },
                        idioma:
                            global.Idioma.toLowerCase() === "es"
                                ? "esp"
                                : "eng",
                        color: btnColor.trim(),
                        fuente: global.font,
                        showSkeletonLoading: false,
                        horizontal:document.body.getBoundingClientRect().width< 800,
                        fnValidacionEsAsync: true,
                        metodos: selectedFunction.Paypal
                            ? methods
                                  .split(",")
                                  .filter((x) => x.length > 0)
                                  .concat("paypal")
                                  .join()
                            : methods,
                    },
                    callBackLukaPay,
                    validateFormPay,
                    () => {
                        closeSkeleton();
                    },
                    {
                        nombrePagador: `${
                            document.getElementById("firstName").value
                        } ${document.getElementById("lastName").value}`,
                        cuotasConfig:{
                            idConfig: parseInt(selectedFunction?.CodigoPagoCuotas)
                        },
                    },
                );
            }
        } catch (error) {}
    };

    const callBackLukaPay = async (result, error) => {
        setPreloader(true);
        const FirstName = document.getElementById("firstName")
            ? document.getElementById("firstName").value
            : "";
        const LastName = document.getElementById("lastName")
            ? document.getElementById("lastName").value
            : "";
        const PhoneNumber = document.getElementById("phoneNumber")
            ? document.getElementById("phoneNumber").value
            : "";
        // console.log(document.getElementById("email"));
        const Email = document.getElementById("email")
            ? document.getElementById("email").value
            : "";
        const Nacionalidad = document.getElementById("nacionalidad")
            ? document.getElementById("nacionalidad").value
            : "00000000";
        const Cedula = document.getElementById("cedula")
            ? document.getElementById("cedula").value
            : "";

        if (error && error.Error.length > 0) {
            console.log("error:", error, result);
        }
      
        if (result === null || result === undefined || !result.Exitoso) {
            result = {
                Monto: totalMount,
                MontoUsd: totalMount,
                TarjetaHabiente: {
                    Nombre: FirstName,
                    Apellido: LastName,
                    NumeroTelefono: PhoneNumber,
                },
                Moneda:
                    selectedFunction.Iso === "VES"
                        ? global.MonedaPorDefecto
                        : selectedFunction.Iso,
                TransaccionId:
                    result && result.TransaccionId ? result.TransaccionId : 0,
                TransaccionMerchantId: 0,
                Descripcion:
                    error && error.Error.length > 0
                        ? error.Error[0].Descripcion
                        : result &&
                          result.Descripcion &&
                          result.Descripcion.trim() !== ""
                        ? result.Descripcion
                        : "Error de Luka",
                TrazaId:
                    error && error.TrazaId
                        ? error.TrazaId
                        : result && result.TrazaId
                        ? result.TrazaId
                        : "",
                Exitoso: false,
                MedioDePago:
                    result && result.MedioDePago ? result.MedioDePago : "Luka",
                Canal: "API",
            };
        }

        result.AplicarConversion =
            selectedFunction.Iso === "VES" &&
            global.dolarToday &&
            global.MonedaPorDefecto === "USD" &&
            global.CambioDeMoneda;
        const body = {
            lukaregistrodto: result,
            consumidorskin: {
                email: Email,
                detalleregistroId: detalleRegistro.Id,
                nombre: FirstName,
                apellido: LastName,
                telefono: PhoneNumber,
                enviarEticket: true,
                Idioma: global.Idioma.toLowerCase(),
                paisId: selectedFunction.PaisId,
                skinId: global.skinId,
                dni: Nacionalidad + Cedula,
                trazaId: result.TrazaId,
            },
            operadorid: getParameterByName("idOperador"),
        };
        const order_id = document.getElementById("order_id")
            ? document.getElementById("order_id").value
            : null;

            
        const { status, data } =
        selectedFunction.DDBranchId && order_id
            ? await Services.pay.ProcessPayLukaWithMerch({
                  lukaTransaccionRegistrarDto: body,
                  order_id: order_id,
              })
            : await Services.pay.ProcessPayLuka(body);
        if (
            result &&
            result?.InfoProceso &&
            result?.InfoProceso?.EstatusProcesamiento === "pending"
        ) {
            console.log("reservationSeats",reservationSeats)
            if((result.MedioDePago === "Pago en Cuotas") && (data.UsaCuotas=== true) ){
                history.push({
                    pathname: `/compraparcial`,
                    state: {
                        Nombre: FirstName,
                        Usuario: result.InfoUsuarioPagador.Nombre,
                        Cuotas:result.Cuotas,
                        Monto: totalMount,
                        Email: Email,
                        Dni: Nacionalidad ? `${Nacionalidad}-${Cedula}` : 0,
                        Ref: result.TransaccionId,
                        selectedFunction:selectedFunction,
                        global:global,
                        FechaFinReserva:data?.FechaFinReserva,
                        reservationSeats:reservationSeats,
                        back:window.location.href
                    },
                });
            }else if(data.Message === "Pago pendiente por procesar"){
                history.push({
                    pathname: result.MedioDePago === 'Efectivo' ? `/compraefectivo`: `/compraenproceso`,//`/pendingReview`,
                    state: {
                        Nombre: FirstName,
                        Usuario: result.InfoUsuarioPagador.Nombre,
                        Monto: totalMount,
                        Email: Email,
                        Dni: Nacionalidad ? `${Nacionalidad}-${Cedula}` : 0,
                        Ref: result.TransaccionId,
                        selectedFunction:selectedFunction,
                        global:global,
                        reservationSeats:reservationSeats,
                        FechaFinReserva:data?.FechaFinReserva,
                        back:window.location.href
                    },
                });
            } else {
                handleErrorLuka(true, data.Message);
            }

            //return;
        }
        if (status === 200) {
            //console.log("compraexitosa",result)
            if(result.Exitoso && result.MedioDePago === "Pago en Cuotas" ){
                // console.log("Exitoso",result,data)
                history.push({
                    pathname: `/compraparcial`,
                    state: {
                        Nombre: FirstName,
                        Usuario: result.InfoUsuarioPagador.Nombre,
                        Cuotas:result.Cuotas,
                        Monto: totalMount,
                        Email: Email,
                        Dni: Nacionalidad ? `${Nacionalidad}-${Cedula}` : 0,
                        Ref: result.TransaccionId,
                        selectedFunction:selectedFunction,
                        global:global,
                        FechaFinReserva:data?.FechaFinReserva,
                        reservationSeats:reservationSeats,
                        back:window.location.href
                    },
                });
            } else if (
                result.Exitoso &&
                data.transaccion.error === "" &&
                data.transaccion.transaccionid !== 0
            ) {  
                //localStorage.removeItem("reservationSeats")
                history.push({
                    pathname: `/compraexitosa/${data.transaccion.transaccionid}/2`,
                    state: {
                        confirmacion: data.confirmacion,
                        selectedFunction: selectedFunction,
                        back:window.location.href,
                        consumidorskin:body?.consumidorskin ? body?.consumidorskin :undefined
                    },
                });
            } else {
                if (result.Exitoso) {
                    handleErrorMessage(
                        "Su transaccion fue registrada, si no ha recibido un correo con sus tickets puede comunicarse al correo atencionalcliente@ticketmundo.com"
                    );
                    console.log(
                        "transaccion fallida:  comuniquese con el administrador"
                    );
                } else {
                    handleErrorLuka(
                        true,
                        `Hemos recibido el siguiente error al registrar su pago:${"\b"} ${
                            result.Descripcion
                        } ${"\b"} Por favor Intente de nuevo`
                    );
                    //this.handleErrorMessage();
                    console.log("transaccion fallida:  ", result.Descripcion);
                }
                //this.setState({ paymentFailure: true });
            }
        } else if (status === 409) {
            if (result.Exitoso && data.aprobado) {
                localStorage.removeItem("reservationSeats")
                console.log('2consumidorskin',body)
                history.push({
                    pathname: `/compraexitosa/${data.transaccion_id}/2`,
                    state: {
                        confirmacion: null,
                        selectedFunction: selectedFunction,
                    },
                });
            }
        } else {
            console.log("error al llamar servicio", status);
        }
        setPreloader(false);
    };

    const initPayLuka = async () => {
        try {
            //const { selectedFunction } = props;
            // if (selectedFunction.PaisId !== this.state.chile) {
            const lukaresponse = await Services.pay.initPayLuka(
                selectedFunction.ComercioId,
                selectedFunction.PaisId
            );
            if (lukaresponse.status === 200) {
                setTokenLukaUpdate(
                    lukaresponse.data.Token,
                    lukaresponse.data.Id
                );
                setTokenLuka({
                    token: lukaresponse.data.Token,
                    trazaId: lukaresponse.data.Id,
                });
            } else {
                console.log(lukaresponse.status);
            }
        } catch (error) {
            console.log(error);
        }
    };
    
    return (
        <section className="content">
            <div className="method-pay-mobile">
                <div className="title-g sub-title-bold color-titulos">
                    {
                        !global.userSession.Id? <>
                            <span>{t("labelPaymentMethods")} <span style={{fontWeight: `500`}}>({t("guest")})</span></span>
                            <div className="subtitle_payguest">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 41 40"
                                >
                                    <defs>
                                    <clipPath id="clip-Artboard_1">
                                        <rect width="35" height="35"></rect>
                                    </clipPath>
                                    </defs>
                                    <g
                                    id="Artboard_1"
                                    data-name="Artboard – 1"
                                    clipPath="url(#clip-Artboard_1)"
                                    >
                                    <g
                                        id="Group_3202"
                                        data-name="Group 3202"
                                        transform="translate(-1121.989 -119.583)"
                                    >
                                        <path
                                        id="Path_3516"
                                        data-name="Path 3516"
                                        d="M161.623,258.641a12.755,12.755,0,0,0-5.6-2.132,13,13,0,0,0-1.585-.1h0a12.739,12.739,0,0,0-11.823,8.033,13.379,13.379,0,0,0-.509,1.588,12.735,12.735,0,0,0-.382,3.1c0,.21.006.42.016.627a12.39,12.39,0,0,0,.178,1.585,12.66,12.66,0,0,0,2.718,5.881,13.21,13.21,0,0,0,1.6,1.617,12.7,12.7,0,0,0,8.2,3.007c.4,0,.8-.019,1.187-.057a12.713,12.713,0,0,0,6-23.146Zm-7.189,21.612a11.108,11.108,0,0,1-8.2-3.618,11.26,11.26,0,0,1-1.6-2.256,11.047,11.047,0,0,1-1.1-3.039,10.836,10.836,0,0,1-.2-1.585c-.013-.207-.019-.417-.019-.627a11.048,11.048,0,0,1,.439-3.1,11.308,11.308,0,0,1,.6-1.588A11.144,11.144,0,0,1,154.434,258h0a11.322,11.322,0,0,1,1.585.111,11.127,11.127,0,0,1-1.588,22.141Z"
                                        transform="translate(988.053 -129.545)"
                                        fill="var(--c1)"
                                        ></path>
                                        <path
                                        id="Path_3517"
                                        data-name="Path 3517"
                                        d="M179.97,279.995a.75.75,0,0,1-.8-.7v-9.267a.8.8,0,0,1,1.591,0V279.3A.75.75,0,0,1,179.97,279.995Z"
                                        transform="translate(962.519 -138.357)"
                                        fill="var(--c1)"
                                        ></path>
                                        <path
                                        id="Path_3518"
                                        data-name="Path 3518"
                                        d="M179.97,310.38a.8.8,0,0,1-.8-.8v-1.731a.8.8,0,0,1,1.591,0v1.731A.8.8,0,0,1,179.97,310.38Z"
                                        transform="translate(962.519 -164.073)"
                                        fill="var(--c1)"
                                        ></path>
                                    </g>
                                    </g>
                                </svg>
                                <p>{t("subLabelPaymentMethods")}</p> 
                            </div>
                        </>
                        :<span>{t("labelPaymentMethods")}</span>
                    }
                </div>
                {
                    preloader ? <div className="great_spinner">
                        <div className="spinner-main spinner3">
                        <div className="loadingio-spinner-rolling-k94er4xvtw spinner3-b">
                            <div className="ldio-arie1i327iv spinner3-c">
                            <div
                                style={{
                                border: `10px`,
                                borderStyle: "solid",
                                borderColor: `var(--c1)`,
                                borderTopColor: "transparent",
                                }}
                            ></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    :null
                }
                <div className="info-pago">
                    <div className="wrap-cont">
                        {showSkeleton && <SkeletonLuka />}
                        <div
                            style={
                                showSkeleton
                                    ? {
                                          display: "block",
                                          height: 0,
                                          overflow: "hidden",
                                      }
                                    : { display: "block" }
                            }
                            id="luka-container"
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(document.location.search);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export default withTranslation()(React.memo(FormPay));
